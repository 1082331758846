export default [
    {
        path: '/base_social',
        name: 'socialBases.index',
        component: () => import('@/views/socialBases/SocialBasesList.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
            pageTitle: 'Base Social',
            navActiveLink: 'socialBases.index',
        },
    },
    {
        path: '/base_social/add',
        name: 'socialBases.create',
        component: () =>
            import('@/views/socialBases/SocialBasesAdd.vue'),
        meta: {
            pageTitle: 'Base Social',
            navActiveLink: 'socialBases.index',
        },
    },
    {
        path: '/base_social/edit/:id',
        name: 'socialBases.edit',
        component: () => import('@/views/socialBases/SocialBasesEdit.vue'),
        meta: {
            pageTitle: 'Base Social',
            navActiveLink: 'socialBases.index',
        },
    },
    {
        path: '/base_social/:id',
        name: 'socialBases.show',
        component: () => import('@/views/socialBases/SocialBasesShow.vue'),
        meta: {
            pageTitle: 'Base Social',
            navActiveLink: 'socialBases.index',
        },
    },
]
