export default [
    {
        path: '/proveedores',
        name: 'suppliers.index',
        component: () => import('@/views/suppliers/SuppliersList.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
            pageTitle: 'Proveedores',
            navActiveLink: 'suppliers.index',
        },
    },
    {
        path: '/proveedores/add',
        name: 'suppliers.create',
        component: () =>
            import('@/views/suppliers/SuppliersAdd.vue'),
        meta: {
            pageTitle: 'Proveedores',
            navActiveLink: 'suppliers.index',
        },
    },
    {
        path: '/proveedores/edit/:id',
        name: 'suppliers.edit',
        component: () => import('@/views/suppliers/SuppliersEdit.vue'),
        meta: {
            pageTitle: 'Proveedores',
            navActiveLink: 'suppliers.index',
        },
    },
    {
        path: '/proveedores/:id',
        name: 'suppliers.show',
        component: () => import('@/views/suppliers/SuppliersShow.vue'),
        meta: {
            pageTitle: 'Proveedores',
            navActiveLink: 'suppliers.index',
        },
    },
]
