import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
    getUserDataByCookie,
    isUserLoggedIn,
    role,
    getHomeRouteForLoggedInUser
} from '@/auth/utils'

import auth from './routes/auth'
import users from './routes/users'
import entities from './routes/entities'
import laborEntities from './routes/labor-entities'
import volunteerings from './routes/volunteerings'
import activities from './routes/activities'
import personals from './routes/personals'
import beneficiaries from './routes/beneficiaries'
import laborOffers from './routes/laborOffers'
import socialBase from './routes/socialBases'
import benefactors from './routes/benefactors'
import projects from './routes/projects'
import suppliers from './routes/suppliers'
import generalActivities from './routes/generalActivities'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/home/Home.vue'),
            // meta: {
            //     pageTitle: 'Home',
            //     breadcrumb: [
            //         {
            //             text: 'Home',
            //             active: true,
            //         },
            //     ],
            // },
        },
        // { path: '/', redirect: { name: 'redirect' } },
        ...activities,
        ...auth,
        ...users,
        ...entities,
        ...laborEntities,
        ...laborOffers,
        ...volunteerings,
        ...personals,
        ...beneficiaries,
        ...socialBase,
        ...benefactors,
        ...projects,
        ...suppliers,
        ...generalActivities,


        {
            path: '/forgot-password',
            name: 'auth-forgot-password',
            component: () => import('@/views/auth/ForgotPassword.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            },
        },
        {
            path: '/rewrite-password/:token',
            name: 'reset-password-event',
            component: () => import('@/views/auth/ResetPassword.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
            },
        },
        {
            path: '/not-authorized',
            name: 'misc-not-authorized',
            component: () => import('@/views/NotAuthorized.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = 'none'
    }
  })
  const currentRoute = window.location.pathname;
  const isRole = role()
  const isLoggedIn = isUserLoggedIn()

  router.beforeEach((to, _, next) => {
    if (!canNavigate(to)) {
      // Redirect to login if not logged in
      if (!isLoggedIn) return next({ name: 'login' })

      // If logged in => not authorized
      return next({ name: 'misc-not-authorized' })
    }

    // Redirect if logged in
      if (to.meta.redirectIfLoggedIn && isLoggedIn) {
          let isRoleResponse = false;
          isRole.then((value) => {
              isRoleResponse = value;
          });
          const userData = getUserDataByCookie()
          next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
      }

    return next()
  })

  // ? For splash screen
  // Remove afterEach hook if you are not using splash screen
  router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = 'none'
    }
  })

  export default router
