export default [
    {
        path: '/donantes',
        name: 'benefactors.index',
        component: () => import('@/views/benefactors/BenefactorsList.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
            pageTitle: 'Donantes',
            navActiveLink: 'benefactors.index',
        },
    },
    {
        path: '/donantes/add',
        name: 'benefactors.create',
        component: () =>
            import('@/views/benefactors/BenefactorsAdd.vue'),
        meta: {
            pageTitle: 'Donantes',
            navActiveLink: 'benefactors.index',
        },
    },
    {
        path: '/donantes/edit/:id',
        name: 'benefactors.edit',
        component: () => import('@/views/benefactors/BenefactorsEdit.vue'),
        meta: {
            pageTitle: 'Donantes',
            navActiveLink: 'benefactors.index',
        },
    },
    {
        path: '/donantes/:id',
        name: 'benefactors.show',
        component: () => import('@/views/benefactors/BenefactorsShow.vue'),
        meta: {
            pageTitle: 'Donantes',
            navActiveLink: 'benefactors.index',
        },
    },
]
