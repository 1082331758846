export default [
    // *=======================================---*
    // *---------------- USERS -------------------*
    // *=======================================---*
    {
        path: '/admin/users',
        name: 'users.index',
        component: () =>
            import('@/views/users/UsersList.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
            pageTitle: 'Usuarios',
            navActiveLink: 'users.index',
        },
    },
    {
        path: '/admin/users/add',
        name: 'users.create',
        component: () =>
            import('@/views/users/UsersAdd.vue'),
        meta: {
            pageTitle: 'Usuarios',
            navActiveLink: 'users.index',
            resource: 'Users',
            action: 'read',
        },
    },
    {
        path: '/admin/users/edit/:id',
        name: 'users.edit',
        component: () => import('@/views/users/UsersEdit.vue'),
        meta: {
            pageTitle: 'Usuarios',
            navActiveLink: 'users.index',
        },
    },
]
