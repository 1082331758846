import useJwt from '@/auth/jwt/useJwt'
import { AbilityBuilder, Ability } from "@casl/ability";
import ability from "../libs/acl/ability";
import axios from "@axios";
import crypto from 'crypto';
import Cookies from 'js-cookie';
// import dotenv from 'dotenv';

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
    // return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
    if(checkUserDataCookie())
    {
        return localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
    }
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
    return false;
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getUserDataByCookie = () => {
    const laCookie = Cookies.get('userData');
    if(laCookie == null)
    {
        return false;
    }else{
        return JSON.parse(decrypt(Cookies.get('userData'), process.env.MIX_CIPHER_SALT))
    }
}

export const checkUserDataCookie = () => {
    const laCookie = Cookies.get('userData');

    if(laCookie == null)
    {
        return false;
    }else{
        return true;
    }
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
const appLoading = document.getElementById("loading-bg");
export const getHomeRouteForLoggedInUser = userRole => {
    // appLoading.style.display = "block";
    if (userRole === 'all') return '/'
    if (userRole === 'Client') return { name: 'access-control' }
    return { name: 'login' }
    //     setTimeout(

}

/**
 * Con esta función comprobamos el rol y hacemos update de las abilities del usuario, para no tener que hacer persistencia desde local
 * @returns Promise.resolve()
 *
 */
export const role = async () => {
    const isLoggedIn = isUserLoggedIn()
    if (isLoggedIn) {
        // console.log('estás logueado')
        let yourRole = await getCurrentUserRole();
        if (yourRole) {
            //   ability.update([]);
            const { can, rules } = new AbilityBuilder(Ability);
            yourRole.userData.ability.forEach((ability) => {
                can(ability.action, ability.subject);
            });
            ability.update(rules);
        } else {
            return Promise.resolve(false);
        }
    } else {
        return Promise.resolve(false);
    }
    return Promise.resolve(true);
};
const algorithm = 'aes-128-cbc';
// const iv = Buffer.from('e97c0cb7d6ec7bef2cab47af1e29f1d3');
export const saveUserDataCookie = (userData) => {
    const dataEncrypted = encryptData(userData, process.env.MIX_CIPHER_SALT);
    Cookies.set('userData', dataEncrypted);
};

export const deleteUserDataByCookie = () => {
    Cookies.remove('userData');
};

const encryptData = (data, salt) => {
    const cipher = crypto.createCipher(algorithm, salt);
    let encrypted = cipher.update(data, 'utf8', 'hex');
    encrypted += cipher.final('hex');
    return encrypted;
};
const decrypt = (data, salt) => {
    const decipher = crypto.createDecipher(algorithm, salt);
    let decrypted = decipher.update(data, 'hex', 'utf8');
    decrypted += decipher.final('utf8');
    return decrypted;
};


const getCurrentUserRole = (ctx) => {
    return new Promise((resolve, reject) => {
        axios
            .post("/api/auth/getCurrentUserRole", {
                headers: `Bearer ${isUserLoggedIn()}`,
            })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};


