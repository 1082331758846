export default [
    {
        path: '/entidades',
        name: 'entities.index',
        component: () => import('@/views/entities/EntitiesList.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
            pageTitle: 'Entidades',
            navActiveLink: 'entities.index',
        },
    },
    {
        path: '/entidades/add',
        name: 'entities.create',
        component: () =>
            import('@/views/entities/EntitiesAdd.vue'),
        meta: {
            pageTitle: 'Entidades',
            navActiveLink: 'entities.index',
        },
    },
    {
        path: '/entidades/edit/:id',
        name: 'entities.edit',
        component: () => import('@/views/entities/EntitiesEdit.vue'),
        meta: {
            pageTitle: 'Entidades',
            navActiveLink: 'entities.index',
        },
    },
    {
        path: '/entidades/:id',
        name: 'entities.show',
        component: () => import('@/views/entities/EntitiesShow.vue'),
        meta: {
            pageTitle: 'Entidades',
            navActiveLink: 'entities.index',
        },
    },
]
