import Vue from 'vue'

// axios
import axios from 'axios'
import useJwt from "@/auth/jwt/useJwt";

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    // baseURL: baseURL,
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
})


axiosIns.interceptors.response.use((response) => {
    // console.log('the response status is:', response.status);
    return response;
}, (error) => {
    if (error.response.status === 401) {
        localStorage.clear();
        location.href = "/login";
        return Promise.reject(error.response);
    }
});
Vue.prototype.$http = axiosIns

export default axiosIns
