export default [
    {
        path: '/personal',
        name: 'personals.index',
        component: () => import('@/views/personals/PersonalsList.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
            pageTitle: 'Personal',
            navActiveLink: 'personals.index',
        },
    },
    {
        path: '/personal/add',
        name: 'personals.create',
        component: () =>
            import('@/views/personals/PersonalsAdd.vue'),
        meta: {
            pageTitle: 'Personal',
            navActiveLink: 'personals.index',
        },
    },
    {
        path: '/personal/edit/:id',
        name: 'personals.edit',
        component: () => import('@/views/personals/PersonalsEdit.vue'),
        meta: {
            pageTitle: 'Personal',
            navActiveLink: 'personals.index',
        },
    },
    {
        path: '/personal/:id',
        name: 'personals.show',
        component: () => import('@/views/personals/PersonalsShow.vue'),
        meta: {
            pageTitle: 'Personal',
            navActiveLink: 'personals.index',
        },
    },
]
