export default [
    {
        path: '/voluntariado',
        name: 'volunteerings.index',
        component: () => import('@/views/volunteerings/VolunteeringsList.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
            pageTitle: 'Voluntariado',
            navActiveLink: 'volunteerings.index',
        },
    },
    {
        path: '/voluntariado/add',
        name: 'volunteerings.create',
        component: () =>
            import('@/views/volunteerings/VolunteeringsAdd.vue'),
        meta: {
            pageTitle: 'Voluntariado',
            navActiveLink: 'volunteerings.index',
        },
    },
    {
        path: '/voluntariado/edit/:id',
        name: 'volunteerings.edit',
        component: () => import('@/views/volunteerings/VolunteeringsEdit.vue'),
        meta: {
            pageTitle: 'Voluntariado',
            navActiveLink: 'volunteerings.index',
        },
    },
    {
        path: '/voluntariado/:id',
        name: 'volunteerings.show',
        component: () => import('@/views/volunteerings/VolunteeringsShow.vue'),
        meta: {
            pageTitle: 'Voluntariado',
            navActiveLink: 'volunteerings.index',
        },
    },
]
