export default [
    {
        path: '/',
        name: 'redirect',
        component: () => import('@/views/general/Redirect.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/Login.vue'),
        meta: {
            resource: 'Auth',
            layout: 'full',
            redirectIfLoggedIn: true,
        },
    },
]
