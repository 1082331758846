export default [
    {
        path: '/ofertas_laborales',
        name: 'laborOffers.index',
        component: () => import('@/views/laborOffers/LaborOffersList.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
            pageTitle: 'Ofertas Laborales',
            navActiveLink: 'laborOffers.index',
        },
    },
    {
        path: '/ofertas_laborales/add',
        name: 'laborOffers.create',
        component: () =>
            import('@/views/laborOffers/LaborOffersAdd.vue'),
        meta: {
            pageTitle: 'Ofertas Laborales',
            navActiveLink: 'laborOffers.index',
        },
    },
    {
        path: '/ofertas_laborales/edit/:id',
        name: 'laborOffers.edit',
        component: () => import('@/views/laborOffers/LaborOffersEdit.vue'),
        meta: {
            pageTitle: 'Ofertas Laborales',
            navActiveLink: 'laborOffers.index',
        },
    },
    {
        path: '/ofertas_laborales/:id',
        name: 'laborOffers.show',
        component: () => import('@/views/laborOffers/LaborOffersShow.vue'),
        meta: {
            pageTitle: 'Ofertas Laborales',
            navActiveLink: 'laborOffers.index',
        },
    },
]
