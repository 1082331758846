export default [
    {
        path: '/actividades',
        name: 'activities.index',
        component: () => import('@/views/activities/ActivitiesList.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
            pageTitle: 'Actividades',
            navActiveLink: 'activities.index',
        },
    },
    {
        path: '/actividades/add',
        name: 'activities.create',
        component: () =>
            import('@/views/activities/ActivitiesAdd.vue'),
        meta: {
            pageTitle: 'Actividades',
            navActiveLink: 'activities.index',
        },
    },
    {
        path: '/actividades/edit/:id',
        name: 'activities.edit',
        component: () => import('@/views/activities/ActivitiesEdit.vue'),
        meta: {
            pageTitle: 'Actividades',
            navActiveLink: 'activities.index',
        },
    },
]
