export default [
    {
        path: '/proyectos',
        name: 'projects.index',
        component: () => import('@/views/projects/ProjectsList.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
            pageTitle: 'Proyectos',
            navActiveLink: 'projects.index',
        },
    },
    {
        path: '/proyectos/add',
        name: 'projects.create',
        component: () =>
            import('@/views/projects/ProjectsAdd.vue'),
        meta: {
            pageTitle: 'Proyectos',
            navActiveLink: 'projects.index',
        },
    },
    {
        path: '/proyectos/edit/:id',
        name: 'projects.edit',
        component: () => import('@/views/projects/ProjectsEdit.vue'),
        meta: {
            pageTitle: 'Proyectos',
            navActiveLink: 'projects.index',
        },
    },
    {
        path: '/proyectos/:id',
        name: 'projects.show',
        component: () => import('@/views/projects/ProjectsShow.vue'),
        meta: {
            pageTitle: 'Proyectos',
            navActiveLink: 'projects.index',
        },
    },
]
