export default [
    {
        path: '/beneficiarios',
        name: 'beneficiaries.index',
        component: () => import('@/views/beneficiaries/BeneficiariesList.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
            pageTitle: 'Personas Beneficiarias',
            navActiveLink: 'beneficiaries.index',
        },
    },
    {
        path: '/beneficiarios/add',
        name: 'beneficiaries.create',
        component: () =>
            import('@/views/beneficiaries/BeneficiariesAdd.vue'),
        meta: {
            pageTitle: 'Personas Beneficiarias',
            navActiveLink: 'beneficiaries.index',
        },
    },
    {
        path: '/beneficiarios/edit/:id',
        name: 'beneficiaries.edit',
        component: () => import('@/views/beneficiaries/BeneficiariesEdit.vue'),
        meta: {
            pageTitle: 'Personas Beneficiarias',
            navActiveLink: 'beneficiaries.index',
        },
    },
    {
        path: '/beneficiarios/:id',
        name: 'beneficiaries.show',
        component: () => import('@/views/beneficiaries/BeneficiariesShow.vue'),
        meta: {
            pageTitle: 'Personas Beneficiarias',
            navActiveLink: 'beneficiaries.index',
        },
    },
]
