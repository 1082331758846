export default [
    {
        path: '/actividades_generales',
        name: 'generalActivities.index',
        component: () => import('@/views/generalActivities/GeneralActivitiesList.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
            pageTitle: 'Actividades Generales',
            navActiveLink: 'generalActivities.index',
        },
    },
    {
        path: '/actividades_generales/add',
        name: 'generalActivities.create',
        component: () =>
            import('@/views/generalActivities/GeneralActivitiesAdd.vue'),
        meta: {
            pageTitle: 'Actividades Generales',
            navActiveLink: 'generalActivities.index',
        },
    },
    {
        path: '/actividades_generales/add/:id',
        name: 'generalActivities.createByPersonal',
        component: () =>
            import('@/views/generalActivities/GeneralActivitiesAdd.vue'),
        meta: {
            pageTitle: 'Actividades Generales',
            navActiveLink: 'generalActivities.index',
        },
    },
    {
        path: '/actividades_generales/edit/:id',
        name: 'generalActivities.edit',
        component: () => import('@/views/generalActivities/GeneralActivitiesEdit.vue'),
        meta: {
            pageTitle: 'Actividades Generales',
            navActiveLink: 'generalActivities.index',
        },
    },
]
