export default [
    {
        path: '/entidades-laborales',
        name: 'laborEntities.index',
        component: () => import('@/views/laboralProspection/laborEntities/LaborEntitiesList.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
            pageTitle: 'Entidades Laborales',
            navActiveLink: 'laborEntities.index',
        },
    },
    {
        path: '/entidades-laborales/add',
        name: 'laborEntities.create',
        component: () =>
            import('@/views/laboralProspection/laborEntities/LaborEntitiesAdd.vue'),
        meta: {
            pageTitle: 'Entidades Laborales',
            navActiveLink: 'laborEntities.index',
        },
    },
    {
        path: '/entidades-laborales/edit/:id',
        name: 'laborEntities.edit',
        component: () => import('@/views/laboralProspection/laborEntities/LaborEntitiesEdit.vue'),
        meta: {
            pageTitle: 'Entidades Laborales',
            navActiveLink: 'laborEntities.index',
        },
    },
    {
        path: '/entidades-laborales/:id',
        name: 'laborEntities.show',
        component: () => import('@/views/laboralProspection/laborEntities/LaborEntitiesShow.vue'),
        meta: {
            pageTitle: 'Entidades Laborales',
            navActiveLink: 'laborEntities.index',
        },
    },
]
